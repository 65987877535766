import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchSuppliers, business_selector_actions } from '../../store/business-selector-slice';

const SupplierSelect = ({ selectedMonth }) => {
	// Redux
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const suppliers = useSelector(state => state.business_selector_slice.suppliers);
	const baRole = useSelector(state => state.business_selector_slice.baRole);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	// const baRoles = useSelector(state => state.business_selector_slice.baRoles);
	const dispatch = useDispatch();

	// State
	const [loading, setLoading] = useState(false);

	// API fetch
	useEffect(() => {
		if (!baRole)
			return;
		const handleAsync = async () => {
			setLoading(true);
			await dispatch(fetchSuppliers(businessUnit.BA_ID, baRole));
			setLoading(false);
		};
		handleAsync();
	}, [dispatch, baRole, businessUnit]);

	// Handlers
	const selectedSupplierChange = (event) => {
		if (!event.target.value) {
			return;
		}

		const selectedSupplierID = event.target.value;
		const selectedSupplier = suppliers.find(supplier => supplier.BA_ID === selectedSupplierID);
		dispatch(business_selector_actions.setSelectedSupplier(selectedSupplier));
	};

	return (

		<select
			disabled={!isAdmin}
			className="form-select form-select-sm"
			type="form-select"
			onChange={selectedSupplierChange}
			value={selectedSupplier.BA_ID}
		>
			{
				loading ?
					<option>...Loading</option> :
					<Fragment>
						<option value="">--Select a Supplier--</option>
						{suppliers?.map(supplier => (
							(supplier.TERMDATE == null || supplier.TERMDATE >= selectedMonth) ? // do not display after IN date 
								(
									<option key={supplier.BA_ID} value={supplier.BA_ID}>
										{supplier.NAME}
									</option>
								) : null
						))}
					</Fragment>
			}
		</select>

	);
};

export default SupplierSelect;