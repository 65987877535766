import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { business_selector_actions, fetchAllBusinessUnits } from "../../store/business-selector-slice";

const BusinessUnitSelect = () => {
	// Redux
	const dispatch = useDispatch();
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const allBusinessUnits = useSelector(state => state.business_selector_slice.allBusinessUnits);
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);

	// State
	const [loading, setLoading] = useState(false);
	const [selectedBu, setSelectedBu] = useState(businessUnit.BA_ID);

	// API fetch
	useEffect(() => {
		const handleAsync = async () => {
			setLoading(true);
			await dispatch(fetchAllBusinessUnits()); // fetches and sets
			setLoading(false);
		};
		handleAsync();
	}, [dispatch]);

	useEffect(() => {
	}, [businessUnit])

	const handleSelectChange = (event) => {
		const buBaId = event.target.value;
		setSelectedBu(buBaId);
		dispatch(business_selector_actions.setBusinessUnit(allBusinessUnits?.find(ba => ba.BA_ID === buBaId)));
	}

	return (
		<label className='ms-1 me-1'>
			Business Unit:
			<select
				disabled={!isAdmin} // enabled for admins
				className="form-select form-select-sm"
				type="form-select"
				style={{ maxWidth: "200px" }}
				value={selectedBu}
				onChange={handleSelectChange}
			>
				{
					loading ?
						<option>...Loading</option>
						:
						allBusinessUnits.map(bu => (
							<option key={bu.BA_ID} value={bu.BA_ID}>{bu.NAME}</option>
						))
				}
			</select>
		</label>
	);
};

export default BusinessUnitSelect;